import React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import SimpleAccordion from 'components/accordion/simple'
import PosterCard from 'components/card/poster'
import NewsCard from 'components/card/news'
import ZoomGallery from 'components/gallery/zoom'
import I18nImage from 'components/gallery/i18nImage'

import videoQualityMonitoringList from '/static/data/audienceAnalytics.static'
import streamFunctions from '/static/data/feature.static'
import newsList from '/static/data/newsList.static'

const AudienceAnalyticsPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: 'Video Streaming QoE Analytics Solutions - Mlytics',
            metaDescription:
              "Get detailed QoE & QoS analytics for your video streaming with Mlytics' viewer analytics solutions. Optimize your video content for better engagement.",
            metaUrl: 'https://www.mlytics.com/video-stream/audience-analytics/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2022/06/logo_Mlytics_regular.png'
            }
          }}
        />
        <section className="container flex flex-col md:flex-row mt-10 mb-16">
          <div className="flex-1 mt-10">
            <div className="px-4">
              <h1 className="text-primary-600">{t('audienceAnalytics.section1.title')}</h1>
              <p className="py-5 text-gray-600">{t('audienceAnalytics.section1.description')}</p>
              <div className="flex flex-col lg:flex-row gap-3 my-1">
                <Link to="/sign-up" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                  {t('button.freeTrial.0')}
                </Link>
                <Link
                  to="/lp/get-a-demo/"
                  className="btn btn-bold btn-ghost btn-orange inline-block"
                  style={{ width: 208 }}
                >
                  {t('button.getDemo.0')}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex justify-center flex-1 px-8">
            <ZoomGallery>
              <I18nImage
                src="https://static.mlytics.com/images/website/streaming_analytics.png"
                alt={t('audienceAnalytics.section1.title')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </ZoomGallery>
          </div>
        </section>
        <section className="bg-primary-500 px-4 md:px-10 py-20">
          <div className="container">
            <h2 className="text-white text-center">{t('audienceAnalytics.section2.title')}</h2>
            <div className="flex flex-col md:flex-row pt-20">
              <div className="flex-1">
                <h6 className="text-white text-2xl">{t('audienceAnalytics.section2.list1.title')}</h6>
                <ul className="list-disc mb-7 mt-1 px-5 text-white">
                  <li>{t('audienceAnalytics.section2.list1.item1')}</li>
                  <li>{t('audienceAnalytics.section2.list1.item2')}</li>
                  <li>{t('audienceAnalytics.section2.list1.item3')}</li>
                </ul>
                <h6 className="text-white text-2xl">{t('audienceAnalytics.section2.list2.title')}</h6>
                <ul className="list-disc mb-7 mt-1 px-5 text-white">
                  <li>{t('audienceAnalytics.section2.list2.item1')}</li>
                  <li>{t('audienceAnalytics.section2.list2.item2')}</li>
                  <li>{t('audienceAnalytics.section2.list2.item3')}</li>
                </ul>
              </div>
              <div className="flex-1">
                <StaticImage
                  src="https://static.mlytics.com/images/website/augment_video_data_driven_insights.png"
                  alt={t('audienceAnalytics.section2.imageAlt')}
                  style={{ height: 'auto', maxWidth: '540px' }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container flex flex-col md:flex-row">
            <div className="flex-1 md:px-8">
              <div className="font-black text-lg text-primary-500">{t('audienceAnalytics.section3.subtitle')}</div>
              <h2 className="font-bold text-4xl">{t('audienceAnalytics.section3.title')}</h2>
              <p className="py-5 text-gray-600">{t('audienceAnalytics.section3.description')}</p>
            </div>
            <div className="flex-1 px-8">
              <ZoomGallery>
                <I18nImage
                  src="https://static.mlytics.com/images/website/audience_analytics.png"
                  alt={t('audienceAnalytics.section3.subtitle')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex flex-1 justify-center px-8">
              <ZoomGallery>
                <I18nImage
                  src="https://static.mlytics.com/images/website/video_QoE_monitoring_analytics.png"
                  alt={t('audienceAnalytics.section4.subtitle')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
            <div className="flex-1 md:px-8 pb-5">
              <div className="font-black text-lg text-primary-500">{t('audienceAnalytics.section4.subtitle')}</div>
              <h2 className="font-bold text-4xl">{t('audienceAnalytics.section4.title')}</h2>
              <p className="py-5 text-gray-600">{t('audienceAnalytics.section4.description')}</p>
              <SimpleAccordion list={videoQualityMonitoringList} />
            </div>
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container flex flex-col md:flex-row">
            <div className="flex-1 md:px-8">
              <h2 className="py-5 text-gray-800 text-3xl">{t('audienceAnalytics.section5.title')}</h2>
              <p className="py-5 text-gray-600">{t('audienceAnalytics.section5.description')}</p>
            </div>
            <div className="flex-1 px-8">
              <ZoomGallery>
                <I18nImage
                  src="https://static.mlytics.com/images/website/streaming_individual_viewer_data.png"
                  alt={t('audienceAnalytics.section5.imageAlt')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex flex-1 justify-center px-8">
              <ZoomGallery>
                <I18nImage
                  src="https://static.mlytics.com/images/website/correlate_audience_analytics.png"
                  alt={t('audienceAnalytics.section6.imageAlt')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
            <div className="flex-1 md:px-8">
              <h2 className="py-5 text-gray-800 text-3xl">{t('audienceAnalytics.section6.title')}</h2>
              <p className="py-5 text-gray-600">{t('audienceAnalytics.section6.description')}</p>
            </div>
          </div>
        </section>
        <section className="bg-gradient-blue px-4 md:px-10 py-16">
          <h2 className="text-center text-white text-5xl">{t('heading.learnMoreAboutStream')}</h2>
          <div className="flex flex-col md:flex-row justify-center pt-10">
            {streamFunctions.map((item, index) => {
              return index === 0 ? null : (
                <PosterCard
                  className="basis-0 mx-3 mb-3 md:mb-0"
                  key={index}
                  iconName={item.iconName}
                  title={t(item.title)}
                  paragraph={t(item.paragraph)}
                  anchorHref={item.anchorHref}
                  anchorText={t(item.anchorText)}
                />
              )
            })}
          </div>
          <div className="flex flex-col lg:flex-row gap-3 items-center justify-center mt-10">
            <Link to="/sign-up" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
              {t('button.freeTrial.0')}
            </Link>
            <Link
              to="/lp/get-a-demo/"
              className="btn btn-bold bg-white border-white text-secondary-600 inline-block"
              style={{ width: 208 }}
            >
              {t('button.getDemo.0')}
            </Link>
          </div>
        </section>
        <section className="hidden xl:block px-10 py-24">
          <h2 className="font-black text-center text-4.5xl text-primary-600">{t('heading.productPlatform')}</h2>
          <div className="flex justify-center mt-16">
            <img
              src="https://static.mlytics.com/images/website/mlytics_platform.svg"
              alt={t('imageAlt.platform')}
              style={{ height: 'auto', maxWidth: '1260px' }}
            />
          </div>
        </section>
        <section className="p-10">
          <h2 className="text-center text-primary-600 text-6xl">{t('heading.latestNews')}</h2>
          <div className="flex flex-col md:flex-row items-center justify-center mt-10 mb-20">
            {newsList.map((item, index) => (
              <NewsCard
                key={index}
                className="w-1/4 mx-2 mb-3 md:mb-0"
                flag={t(item.flag)}
                title={t(item.title)}
                subtitle={t(item.subtitle)}
                paragraph={t(item.paragraph)}
                anchorHref={item.anchorHref}
                imageSrc={item.imageSrc}
                imageAlt={t(item.imageAlt)}
              />
            ))}
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default AudienceAnalyticsPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
